import React, { useState } from "react"
import extractAssortmentMatrix from "../../services/extractAssortmentMatrix"
import Spinner from "../Spinner/Spinner"
import Auth from "../Auth/Auth"
import { useRecoilState } from "recoil"
import { userInfoAtom } from "../../recoil/atom"
import "./App.css"
import { currentEnv } from "../../config"

const App = () => {
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [userInfo] = useRecoilState(userInfoAtom)
  const [error, setError] = useState("")

  console.log(currentEnv)

  const onInputChange = (event) => {
    setError("")
    setSelectedFile(event.target.files[0])
  }

  const downloadAsCSV = (text) => {
    const hiddenElement = document.createElement("a")
    hiddenElement.href = "data:attachment/text," + encodeURI(text)
    hiddenElement.target = "_blank"
    hiddenElement.download = "AssortmentMatrix.csv"
    hiddenElement.click()
    setLoading(false)
  }

  const onExtractCSVClick = () => {
    setError(false)
    setLoading(true)
    extractAssortmentMatrix(selectedFile)
      .then((result) => {
        downloadAsCSV(result)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }

  // Uncomment to enable azure ad
  // if (!userInfo) {
  //   return <Auth />
  // }

  return (
    <div className="App">
      <h1>Acne Studios</h1>
      <p>
        Extract Assortment Matrix from BUY file
        <input type="file" id="input" onChange={(event) => onInputChange(event)} />
      </p>
      {selectedFile && (
        <p>
          <button onClick={() => onExtractCSVClick()}>Extract CSV</button>
          {loading && <Spinner />}
          {error && <span className="error">{error}</span>}
        </p>
      )}
    </div>
  )
}

export default App
