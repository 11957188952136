import React from "react"
import ReactDOM from "react-dom"
import Routes from "./Routes"
import { RecoilRoot } from "recoil"
import "./index.css"

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
)
