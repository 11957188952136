import axios from "axios"
import { azureFunctionUrl } from "../config"

const extractAssortmentMatrix = async (file) => {
  try {
    const result = await axios({
      method: "post",
      url: azureFunctionUrl,
      data: file,
    })
    return result.data
  } catch (error) {
    throw error.message
  }
}

export default extractAssortmentMatrix
